import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ABOUT_FEATURE_KEY, AboutState } from './about.reducer';

export const selectAboutsState =
  createFeatureSelector<AboutState>(ABOUT_FEATURE_KEY);

export const selectDesktopVersionState = createSelector(
  selectAboutsState,
  (state: AboutState) => state.desktop,
);

export const selectDesktopVersion = createSelector(
  selectDesktopVersionState,
  (state) => state.version,
);

export const selectWebVersionState = createSelector(
  selectAboutsState,
  (state: AboutState) => state.web,
);

export const selectWebVersion = createSelector(
  selectWebVersionState,
  (state) => state.version,
);

export const selectChecking = createSelector(
  selectAboutsState,
  (state: AboutState) => state.checking,
);
