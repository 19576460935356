import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter, from, map, Observable, of, Subscription, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@chassis/shared/services/logger';
import { UpdaterInterface } from './updater.interface';
import { ENVIRONMENT } from '@chassis/shared/environment';
import { Store } from '@ngrx/store';
import { NotificationsActions } from '@chassis/shared/notifications';
import { LogrocketService } from '@chassis/shared/services/logrocket';

interface NgswConfig {
  appData: {
    version: string;
  };
}
export enum VersionEventType {
  NoNewVersionDetected = 'NO_NEW_VERSION_DETECTED',
  VersionDetected = 'VERSION_DETECTED',
  VersionReady = 'VERSION_READY',
  VersionInstallationFailed = 'VERSION_INSTALLATION_FAILED',
}

@Injectable({
  providedIn: 'root',
})
export class PwaUpdaterService implements UpdaterInterface {
  readonly env = inject(ENVIRONMENT);
  readonly swUpdate = inject(SwUpdate);
  readonly http = inject(HttpClient);
  readonly logger = inject(LoggerService);
  readonly store = inject(Store);
  readonly logrocketService = inject(LogrocketService);
  private updateInterval = 0;
  private versionUpdateSub: Subscription | undefined;
  initialize() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }

    this.updateInterval = window.setInterval(
      () => {
        if (this.swUpdate.isEnabled) {
          this.swUpdate.checkForUpdate().then((u) => {
            if (u) {
              this.logrocketService.track('updateInterval');
              this.triggerUpdate();
            }
          });
        }
        // check for update every 5 minutes
      },
      5 * 60 * 1000,
    ) as number;

    if (!this.versionUpdateSub) {
      this.versionUpdateSub = this.swUpdate.versionUpdates
        .pipe(
          filter((event: any) => {
            return (
              event?.currentVersion?.appData?.version !=
              event?.latestVersion?.appData?.version
            );
          }),
        )
        .subscribe(() => {
          this.logrocketService.track('versionUpdateEvent');
          console.log('versionUpdateEvent triggered');
          this.triggerUpdate();
        });
    }
  }

  getCurrentVersion(): Observable<string | null> {
    return of(this.env.VERSION);
  }

  checkAndNotify(): Observable<boolean> {
    if (!this.swUpdate?.isEnabled) {
      return of(false);
    }
    return from(this.swUpdate.checkForUpdate()).pipe(
      tap((update) => {
        if (!update) {
          return;
        }
        this.triggerUpdate();
      }),
      map(() => {
        return true;
      }),
    );
  }

  private getVersionFromConfig(config: NgswConfig): string | null {
    const version = config?.appData?.version;
    return version || 'unavailable';
  }

  triggerUpdate() {
    if (window.confirm('New version available. Load New Version?')) {
      this.store.dispatch(
        NotificationsActions.message({
          message: 'Updating application',
          notificationType: 'info',
        }),
      );

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }
}
