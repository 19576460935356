<ng-container
  *ngrxLet="{
    desktopCurrent: desktopCurrent$,
    webCurrent: webCurrent$,
    checking: checking$,
  } as vm"
>
  <article>
    <h3>APFusion</h3>
    <div class="version-container">
      <ng-container
        *ngTemplateOutlet="
          versionTpl;
          context: { type: versionType.Web, current: vm.webCurrent }
        "
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="
          versionTpl;
          context: { type: versionType.Desktop, current: vm.desktopCurrent }
        "
      ></ng-container>
    </div>

    <tui-loader
      class="inline-flex tui-space_top-2 tui-space_bottom-2"
      [showLoader]="vm.checking"
      [inheritColor]="true"
      [overlay]="true"
    >
      <button
        tuiButton
        type="button"
        size="s"
        appearance="apf-outline-primary-btn"
        (click)="onCheckForUpdates()"
      >
        Check For Updates
      </button>
    </tui-loader>

    <small>{{ copyright }}</small>
  </article>
</ng-container>

<ng-template
  #versionTpl
  let-current="current"
  let-latest="latest"
  let-type="type"
>
  @if (current?.version) {
    <section>
      <div class="tui-text_bold">{{ type | titlecase }} Version</div>
      <div>
        Installed Version:
        <ng-container
          *ngTemplateOutlet="versionLabel; context: { $implicit: current }"
        ></ng-container>
      </div>
    </section>
  }
</ng-template>

<ng-template #versionLabel let-data let-type="type">
  @if (!data?.loaded) {
    <tui-loader size="s" [showLoader]="data?.loading"></tui-loader>
  } @else {
    <p>{{ data?.version || 'unavailable' }}</p>
  }
</ng-template>
