import { inject, Injectable } from '@angular/core';
import { IpcChannels } from '@chassis/shared/models';
import { BehaviorSubject } from 'rxjs';
import { ElectronService } from '@chassis/shared/services/electron';

@Injectable({
  providedIn: 'root',
})
export class IpcService {
  readonly electronService = inject(ElectronService);

  private _hollanderSearch$ = new BehaviorSubject<
    { hollanders: string[]; key: string } | undefined
  >(undefined);
  public hollanderSearch$ = this._hollanderSearch$.asObservable();

  constructor() {
    if (this.electronService.isElectron) {
      this.receive(IpcChannels.hollanderSearch, ([hollanders]: any) => {
        this._hollanderSearch$.next(hollanders);
      });
    }
  }

  receive(channel: IpcChannels, func: (...args: any[]) => void) {
    this.electronService.receive(channel, func);
  }

  send(channel: IpcChannels, data?: any) {
    this.electronService.send(channel, data);
  }

  invoke(channel: IpcChannels, ...args: any[]) {
    return this.electronService.invoke(channel, ...args);
  }
}
