import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  delay,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  timeout,
} from 'rxjs';
import { PwaUpdaterService } from '../services/pwa-updater.service';
import { DesktopUpdaterService } from '../services/desktop-updater.service';
import * as AboutActions from './about.actions';
import { VersionType } from './about.models';
import { SharedActions } from '@chassis/shared/actions';

@Injectable()
export class AboutEffects {
  private actions$ = inject(Actions);
  private pwaUpdaterService = inject(PwaUpdaterService);
  private desktopUpdaterService = inject(DesktopUpdaterService);

  globalInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPreAuth),
      switchMap(() => {
        return [
          AboutActions.getCurrentVersion({ versionType: VersionType.Desktop }),
          AboutActions.getCurrentVersion({ versionType: VersionType.Web }),
        ];
      }),
    ),
  );

  desktopGetCurrentVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.getCurrentVersion),
      filter((action) => action.versionType === VersionType.Desktop),
      switchMap((action) => {
        const { versionType } = action;
        return this.desktopUpdaterService.getCurrentVersion().pipe(
          map((version) =>
            AboutActions.getCurrentVersionSuccess({ versionType, version }),
          ),
          catchError((error) =>
            of(AboutActions.getCurrentVersionFailure({ versionType, error })),
          ),
        );
      }),
    ),
  );

  webGetCurrentVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.getCurrentVersion),
      filter((action) => action.versionType === VersionType.Web),
      switchMap((action) => {
        const { versionType } = action;
        return this.pwaUpdaterService.getCurrentVersion().pipe(
          map((version) =>
            AboutActions.getCurrentVersionSuccess({ versionType, version }),
          ),
          catchError((error) =>
            of(AboutActions.getCurrentVersionFailure({ versionType, error })),
          ),
        );
      }),
    ),
  );

  checkForUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.checkForUpdates),
      switchMap(() =>
        forkJoin([
          this.pwaUpdaterService.checkAndNotify(),
          this.desktopUpdaterService.checkAndNotify(),
        ]).pipe(
          delay(2000),
          timeout({ first: 10000 }),
          map(() => AboutActions.checkForUpdatesComplete()),
          catchError(() => of(AboutActions.checkForUpdatesComplete())),
        ),
      ),
    ),
  );
}
