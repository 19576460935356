import { createAction, props } from '@ngrx/store';
import { VersionType } from './about.models';

export enum Type {
  GetCurrentVersion = '[ABOUT] Get Current Version',
  GetCurrentVersionSuccess = '[ABOUT] Get Current Version Success',
  GetCurrentVersionFailure = '[ABOUT] Get Current Version Failure',

  CheckForUpdates = '[ABOUT] Check For Updates',
  CheckForUpdatesComplete = '[ABOUT] Check For Updates Complete',
}

export const getCurrentVersion = createAction(
  Type.GetCurrentVersion,
  props<{ versionType: VersionType }>(),
);
export const getCurrentVersionSuccess = createAction(
  Type.GetCurrentVersionSuccess,
  props<{ versionType: VersionType; version: string | null }>(),
);
export const getCurrentVersionFailure = createAction(
  Type.GetCurrentVersionFailure,
  props<{ versionType: VersionType; error: string }>(),
);

export const checkForUpdates = createAction(Type.CheckForUpdates);

export const checkForUpdatesComplete = createAction(
  Type.CheckForUpdatesComplete,
);
