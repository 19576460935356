import { Action, createReducer, on } from '@ngrx/store';

import * as AboutActions from './about.actions';
import { VersionType } from './about.models';

export const ABOUT_FEATURE_KEY = 'about';

export interface Version {
  version: string | null;
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

export interface AboutState {
  desktop: Version;
  web: Version;
  checking: boolean;
}

export interface AboutPartialState {
  readonly [ABOUT_FEATURE_KEY]: AboutState;
}

export const initialAboutState: AboutState = {
  desktop: {
    loading: false,
    loaded: false,
    version: null,
    error: null,
  },
  web: {
    loading: false,
    loaded: false,
    version: null,
    error: null,
  },
  checking: false,
};

const reduceVersion = (
  state: AboutState,
  versionType: VersionType,
  version: Version,
): AboutState => {
  switch (versionType) {
    case VersionType.Desktop:
      return {
        ...state,
        desktop: version,
      };
    case VersionType.Web:
      return {
        ...state,
        web: version,
      };
  }
};

const reducer = createReducer(
  initialAboutState,

  on(AboutActions.getCurrentVersion, (state, { versionType }) => {
    return reduceVersion(state, versionType, {
      loading: true,
      loaded: false,
      version: null,
      error: null,
    });
  }),
  on(
    AboutActions.getCurrentVersionSuccess,
    (state, { version, versionType }) => {
      return reduceVersion(state, versionType, {
        loading: false,
        loaded: true,
        version,
        error: null,
      });
    },
  ),
  on(AboutActions.getCurrentVersionFailure, (state, { error, versionType }) => {
    return reduceVersion(state, versionType, {
      loading: false,
      loaded: true,
      version: null,
      error,
    });
  }),
  on(AboutActions.checkForUpdates, (state) => {
    return {
      ...state,
      checking: true,
    };
  }),
  on(AboutActions.checkForUpdatesComplete, (state) => {
    return {
      ...state,
      checking: false,
    };
  }),
);

export function aboutReducer(state: AboutState | undefined, action: Action) {
  return reducer(state, action);
}
