import {
  PolymorpheusTemplate,
  PolymorpheusOutlet,
} from '@taiga-ui/polymorpheus';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { aboutReducer } from './store/about.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AboutEffects } from './store/about.effects';
import { PwaUpdaterService } from './services/pwa-updater.service';
import { DesktopUpdaterService } from './services/desktop-updater.service';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TuiLoader, TuiButton } from '@taiga-ui/core';
import { AboutSectionComponent } from './components/about-section/about-section.component';

@NgModule({
  imports: [
    CommonModule,
    RouterLink,
    NgOptimizedImage,
    LetDirective,
    ClipboardModule,
    PolymorpheusTemplate,
    PolymorpheusOutlet,
    TuiButton,
    TuiLoader,

    StoreModule.forFeature('about', aboutReducer),
    EffectsModule.forFeature([AboutEffects]),
  ],
  providers: [PwaUpdaterService, DesktopUpdaterService],
  declarations: [AboutSectionComponent],
  exports: [AboutSectionComponent],
})
export class AboutModule {}
