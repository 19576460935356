import { ElectronService } from '@chassis/shared/services/electron';
import { IpcService } from './lib/ipc.service';

export { IpcService } from './lib/ipc.service';

export function IpcFactory(
  electronService: ElectronService,
): IpcService | null {
  if (electronService.isElectron) {
    return new IpcService();
  }
  return null;
}
