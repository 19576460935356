import { inject, Injectable } from '@angular/core';
import {
  catchError,
  defer,
  from,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { UpdaterInterface } from './updater.interface';
import { IpcService } from '@chassis/shared/services/ipc';
import { ElectronService } from '@chassis/shared/services/electron';
import { IpcChannels } from '@chassis/shared/models';
import { LoggerService } from '@chassis/shared/services/logger';

export enum AutoUpdateAction {
  Initialize = 'initialize',
  GetCurrentVersion = 'get-current-version',
  CheckForUpdate = 'check-for-update',
  InstallUpdate = 'intsall-update',
}

@Injectable({
  providedIn: 'root',
})
export class DesktopUpdaterService implements UpdaterInterface {
  readonly ipcService = inject(IpcService);
  readonly electronService = inject(ElectronService);
  readonly logger = inject(LoggerService);

  initialized$!: Observable<boolean>;

  constructor() {
    if (!this.electronService.isElectron) {
      this.initialized$ = of(true);
      return;
    }

    this.initialized$ = this.requestInitialization();
  }

  getCurrentVersion(): Observable<string | null> {
    return this.initialized$.pipe(
      mergeMap((initialized) =>
        iif(
          () => initialized && this.electronService.isElectron,
          defer(() => this.requestDesktopCurrentVersion()),
          defer(() => of(null)),
        ),
      ),
    );
  }

  checkAndNotify(): Observable<boolean> {
    return this.initialized$.pipe(
      switchMap((initialized) =>
        iif(
          () => initialized && this.electronService.isElectron,
          defer(() => this.requestCheckForUpdate()),
          of(false),
        ),
      ),
    );
  }

  private requestInitialization(): Observable<boolean> {
    const promise = this.ipcService?.invoke(IpcChannels.updater, {
      type: AutoUpdateAction.Initialize,
    });

    if (!promise) {
      return of(false);
    }

    return from(promise).pipe(
      map(() => true),
      catchError((error) => {
        this.logger.error(error);
        return of(false);
      }),
    );
  }

  private requestDesktopCurrentVersion(): Observable<string | null> {
    const promise = this.ipcService?.invoke(IpcChannels.updater, {
      type: AutoUpdateAction.GetCurrentVersion,
    });

    if (!promise) {
      return of(null);
    }

    return from(promise).pipe(
      map((version) => (version as string) || null),
      catchError((error) => {
        this.logger.error(error);
        return of(null);
      }),
    );
  }

  private requestCheckForUpdate(): Observable<boolean> {
    const promise = this.ipcService?.invoke(IpcChannels.updater, {
      type: AutoUpdateAction.CheckForUpdate,
    });

    if (!promise) {
      return of(false);
    }

    return from(promise).pipe(
      map((version) => !!version),
      catchError((error) => {
        this.logger.error(error);
        return of(false);
      }),
    );
  }
}
